<template>
    <div></div>
</template>
<script setup>
import axios from 'axios'
import { useStorage } from "vue3-storage";
import { useAuth } from '@/composables/Auth';

const { logout } = useAuth();
const storage = useStorage()

axios.defaults.headers.common['Authorization'] = 'Bearer ' + storage.getStorageSync('token')
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

logout()
</script>